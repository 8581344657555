import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6f15bfca = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _1dd1d353 = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _448d3d76 = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _0a93f430 = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _1a134288 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _fb8c1024 = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _3b38342c = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _df805bf2 = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _7fdb3960 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _9260881e = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _f8951ff6 = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _119e11aa = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _5f4dc8f0 = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _a1d20000 = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _a8bd5c2a = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _c0f86bdc = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _05dcbdc8 = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _114b6993 = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _128eb8f3 = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _0efcd31c = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _3593c22a = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _2a8b8944 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _5a4a104f = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _27e50155 = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _22be16f0 = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _1973fcd0 = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _e0db7fa4 = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _780c987a = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _11258eb1 = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _0e98203c = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _756d748d = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _209172c5 = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _66426572 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _cd59cf86 = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _7f321118 = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _93261834 = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _592c1318 = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _8c39c450 = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _37a2f0d8 = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _bd6b3338 = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _0227f5db = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _22e0f89c = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _0fdc7335 = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _17cadc01 = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _4dc544aa = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _0ea796e6 = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _b8a5faf2 = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _201e274e = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _5b623a16 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _70db1109 = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _2a344c7b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _50073dae = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _158d9b93 = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _3f20d970 = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _56ad3b6f = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _08a96756 = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _7580c781 = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _0c60e465 = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _1c7af338 = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _1ef93635 = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _50f7626d = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _8c7d0d00 = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _a91b257a = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _3a18255e = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _2d237c64 = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _9dbf7220 = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _a6fe0c90 = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _4b79afad = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _36dd0aa1 = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _01760996 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _455b0b63 = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _7d5ddff5 = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _0b4e6f5a = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _6f15bfca,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _1dd1d353,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _448d3d76,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _0a93f430,
    name: "calendar"
  }, {
    path: "/customer",
    component: _1a134288,
    name: "customer"
  }, {
    path: "/details",
    component: _fb8c1024,
    name: "details"
  }, {
    path: "/goals",
    component: _3b38342c,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _df805bf2,
    name: "maisoku"
  }, {
    path: "/media",
    component: _7fdb3960,
    name: "media"
  }, {
    path: "/privacy",
    component: _9260881e,
    name: "privacy"
  }, {
    path: "/project",
    component: _f8951ff6,
    name: "project"
  }, {
    path: "/response",
    component: _119e11aa,
    name: "response"
  }, {
    path: "/achievement/output",
    component: _5f4dc8f0,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _a1d20000,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _a8bd5c2a,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _c0f86bdc,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _05dcbdc8,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _114b6993,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _128eb8f3,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _0efcd31c,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _3593c22a,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _2a8b8944,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _5a4a104f,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _27e50155,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _22be16f0,
    name: "response-registration"
  }, {
    path: "/setting/announcement",
    component: _1973fcd0,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _e0db7fa4,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _780c987a,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _11258eb1,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _0e98203c,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _756d748d,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _209172c5,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _66426572,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _cd59cf86,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _7f321118,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _93261834,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _592c1318,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _8c39c450,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _37a2f0d8,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _bd6b3338,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _0227f5db,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _22e0f89c,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _0fdc7335,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _17cadc01,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _4dc544aa,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/registration",
    component: _0ea796e6,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _b8a5faf2,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _201e274e,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _5b623a16,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _70db1109,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _2a344c7b,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _50073dae,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _158d9b93,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _3f20d970,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _56ad3b6f,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _08a96756,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _7580c781,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _0c60e465,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _1c7af338,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _1ef93635,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _50f7626d,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _8c7d0d00,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _a91b257a,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _3a18255e,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _2d237c64,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _9dbf7220,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _a6fe0c90,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _4b79afad,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _36dd0aa1,
    name: "property-property",
    children: [{
      path: "contact",
      component: _01760996,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _455b0b63,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _7d5ddff5,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _0b4e6f5a,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
